@import url(base.css);

@import url(slider.css);

@import url(animate.min.css);

@import url(bootstrap.min.css);

@import url(magnific-popup.css);

@import url(owl.carousel.css);

@import url(style.css);

@import url(style-responsive.css);

@import url(vertical-rhythm.min.css);

.page-section footer {
  background: #000;
}

.small-height .sub-menu {
  background: black;
}

.small-height .sub-menu-parent ul li a {
  height: auto !important;
  line-height: inherit !important;
}

.project-header .sub-menu {
  background: black;
}

.project-header .sub-menu-parent ul li a {
  height: auto !important;
  line-height: inherit !important;
}

