@import "base.css";
@import "slider.css";
@import "animate.min.css";
@import "bootstrap.min.css";
@import "magnific-popup.css";
@import "owl.carousel.css";
@import "style.css";
@import "style-responsive.css";
@import "vertical-rhythm.min.css";

.page-section footer {
  background: #000;
}

.small-height .sub-menu li {

}


.small-height .sub-menu {
  background: black;
}

.small-height .sub-menu-parent ul li a {
  height: auto !important;
  line-height: inherit !important;
}


.project-header .sub-menu {
  background: black;
}

.project-header .sub-menu-parent ul li a {
  height: auto !important;
  line-height: inherit !important;
}