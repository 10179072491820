article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}

audio, canvas, video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden] {
    display: none;
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

a:focus {
    outline: thin dotted;
}

a:active, a:hover {
    outline: 0;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b, strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

mark {
    background: #ff0;
    color: #000;
}

code, kbd, pre, samp {
    font-family: monospace, serif;
    font-size: 1em;
}

pre {
    white-space: pre-wrap;
}

q {
    quotes: "\201C" "\201D" "\2018" "\2019";
}

small {
    font-size: 80%;
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
}

button, input, select, textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
}

button, input {
    line-height: normal;
}

button, select {
    text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled], html input[disabled] {
    cursor: default;
}

input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

html {
    background: #1d2121;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    min-height: 100vh;
    color: #57585c;
    color: var(--color-text);
    background-color: #fff;
    background-color: var(--color-bg);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Fade effect */
.js body {
    opacity: 0;
    transition: opacity 0.3s;
}

.js body.render {
    opacity: 1;
}

/* Page Loader */
.js .loading::before {
    content: '';
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.js .loading::after {
    content: '';
    position: fixed;
    z-index: 100000;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    pointer-events: none;
    border-radius: 50%;
    opacity: 0.4;
    background: var(--color-link);
    animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
    to {
        opacity: 1;
        transform: scale3d(0.5, 0.5, 1);
    }
}

a {
    text-decoration: none;
    color: var(--color-link);
    outline: none;
}

a:hover,
a:focus {
    color: var(--color-link-hover);
    outline: none;
}

.hidden {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
}

.message {
    position: relative;
    z-index: 100;
    padding: 1.5em 1em;
    font-size: 0.85em;
    font-weight: bold;
    text-align: center;
    color: var(--color-bg);
    background: var(--color-text);
}

@supports (-webkit-clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)) or (clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)) {
    .message {
        display: none;
    }
}

/* Icons */
.icon {
    display: block;
    width: 1.5em;
    height: 1.5em;
    margin: 0 auto;
    fill: currentColor;
}

.icon--keyboard {
    display: none;
}

main {
    position: relative;
    width: 100%;
}

.content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-height: 100vh;
}

.content--fixed {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    display: grid;
    align-content: space-between;
    width: 100%;
    max-width: none;
    min-height: 0;
    height: 100vh;
    padding: 1.5em;
    pointer-events: none;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto 4em;
    grid-template-areas: 'header header' '... ...' 'github demos';
}

.content--fixed a {
    pointer-events: auto;
}

.content--top {
    padding: 1.5em 2em 1.5em 1.5em;
    justify-content: flex-start;
    min-height: 0;
    position: relative;
}

/* Header */
.codrops-header {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    align-self: start;
    grid-area: header;
    justify-self: start;
}

.demo-2 .codrops-header {
    justify-self: stretch;
}

.codrops-header__title {
    font-size: 1em;
    font-weight: bold;
    margin: 0;
}

.info {
    margin: 0 0 0 1.25em;
    color: var(--color-info);
}

.github {
    display: block;
    align-self: end;
    grid-area: github;
    justify-self: start;
}

.content--top .github {
    align-self: center;
    margin: 0 1em 0 auto;
}

.demos {
    position: relative;
    display: block;
    align-self: end;
    text-align: right;
    grid-area: demos;
}

.content--top .demos {
    align-self: center;
}

.demo {
    margin: 0 0 0 1em;
}

.demo:hover,
.demo:focus {
    opacity: 0.5;
}

.demo span {
    white-space: nowrap;
    pointer-events: none;
}

a.demo--current {
    pointer-events: none;
    color: var(--color-link-hover);
}

/* Top Navigation Style */
.codrops-links {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 1em 0 0;
    text-align: center;
    white-space: nowrap;
}

.codrops-icon {
    display: inline-block;
    margin: 0.15em;
    padding: 0.25em;
}

@media screen and (max-width: 55em) {
    .message {
        display: block;
    }

    .content {
        flex-direction: column;
        height: auto;
        min-height: 0;
    }

    .content--fixed {
        position: relative;
        z-index: 1000;
        display: block;
        padding: 0.85em;
    }

    .codrops-header {
        flex-direction: column;
        align-items: center;
        align-self: center;
    }

    .codrops-header__title {
        font-weight: bold;
        padding-bottom: 0.25em;
        text-align: center;
    }

    .github {
        display: block;
        margin: 1em auto;
    }

    .content--top .github {
        margin: 1em 0;
    }

    .codrops-links {
        margin: 0;
    }

    .demos {
        text-align: center;
    }

    .demo {
        margin: 0 0.5em;
    }
}
